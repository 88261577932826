import { CommonModule, DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MenuItem } from 'primeng/api';
import { ContextMenu, ContextMenuModule } from 'primeng/contextmenu';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { Subscription } from 'rxjs';
import { API_END_POINTS } from '../../constants/constant';
import {
  UserData,
  UserRoles,
} from '../../interfaces/user-management.interface';
import { HttpService } from '../../services/http.service';
import { SortByComponent } from '../sort-by/sort-by.component';
import { CommonService } from '@services/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-table',
  standalone: true,
  imports: [
    TableModule,
    CommonModule,
    ContextMenuModule,
    DropdownModule,
    FormsModule,
    SortByComponent,
    PaginatorModule,
    DatePipe,
  ],
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss',
})
export class TableComponent {
  totalPages: number = 1;
  selectedId!: number;
  ref!: DynamicDialogRef;
  modalContentTxt: string = '';
  isMobileView: boolean = false;
  showUserType = true;
  pageOptions: any[] = [];
  confirmSubscription?: Subscription;

  @Input() tableStyle!: { [key: string]: string };
  @Input() headers!: any[];
  @Input() tableData: any = [];
  @Input() actionItems!: MenuItem[] | any;
  @Input() isGroupedTable: boolean = false;
  @Input() isEquipmentDownTime: boolean = false;
  @Input() currentPage: number = 1;
  @Input() totalRecords: number = 0;
  @Input() pageSize: number = 10;
  @Input() rows: number = 10;
  @Input() tableClass!: string;
  @Input() isPagination: boolean = true;

  @Output() pageChange = new EventEmitter<number>();
  @Output() roleChange = new EventEmitter<{
    data: UserData;
    newRole: UserRoles;
  }>();

  @ViewChild(ContextMenu) contextMenu!: ContextMenu;

  constructor(
    private httpService: HttpService,
    private commonService: CommonService,
    private router: Router
  ) {}

  roleOptions = [
    { name: 'User', id: 1 },
    { name: 'Admin', id: 2 },
  ];

  ngOnInit(): void {
    this.commonService.isMobileSubject.subscribe((res: any) => {
      this.isMobileView = res;
    });
    this.getUserRoles();
    this.updateTotalPages();
  }
  clickOnPlantName(data: any) {
    this.router.navigate(['/chart/daily-production']);
    localStorage.setItem('plantId', data.id.toString());
  }
  getUserRoles() {
    const url = `${API_END_POINTS.GET_USER_ROLES}`;
    this.httpService
      .getRequest(url, null)
      .then((response: any) => {
        if (response) {
          this.roleOptions = response.data;
        } else {
          console.error('No data received');
        }
      })
      .catch((error) => {
        console.error('There was an error:', error);
      });
  }

  onActionsClick(event: MouseEvent, data: any, detail?: any) {
    const actions =
      typeof this.actionItems === 'function'
        ? this.actionItems(data, detail)
        : this.actionItems;
    this.contextMenu.model = actions;
    this.contextMenu.show(event);
  }

  onRoleChange(event: any, rowData: any) {
    this.showUserType = false;
    setTimeout(() => {
      this.showUserType = true;
      this.roleChange.emit({ data: rowData, newRole: event });
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['totalRecords'] || changes['pageSize']) {
      this.updateTotalPages();
    }
  }

  updateTotalPages() {
    this.totalPages = Math.ceil(this.totalRecords / this.rows);
    if (this.totalPages === 0) {
      this.totalPages = 1;
    }
    this.pageOptions = Array.from({ length: this.totalPages }, (v, k) => ({
      label: (k + 1).toString(),
      value: k + 1,
    }));
  }

  onPageChange(event: any) {
    this.pageChange.emit(event.value);
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.pageChange.emit(this.currentPage - 1);
    }
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.pageChange.emit(this.currentPage + 1);
    }
  }
}
